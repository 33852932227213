<template>
  <path id="Union_12" data-name="Union 12" d="M23.966,64H10.659V61.335h2.484L8.3,55.522l2.047-1.707,6.269,7.52h7.35a2.387,2.387,0,0,0,1.9-.707,3.665,3.665,0,0,0,.753-1.961H19.359L10.051,48H7.991c-3.09,0-3.835,2.926-4.013,4.184-.451,3.2-1.313,10.564-1.33,10.638L0,62.512c.009-.074.882-7.468,1.337-10.7.563-4,3.112-6.48,6.654-6.48h3.271L20.57,56h8.588l.133,1.185h0a7.989,7.989,0,0,1-.73,4.148H53.324V64Zm6.688-4V57.335H41.9l6.4-10.667H27.367l-4.938,7.407L20.21,52.6l5.333-8A1.337,1.337,0,0,1,26.654,44h24A1.332,1.332,0,0,1,51.8,46.021l-8,13.334A1.332,1.332,0,0,1,42.655,60ZM6.659,36.954V35.048a6.677,6.677,0,1,1,13.334,0v1.906a6.677,6.677,0,1,1-13.334,0Zm2.668-1.906v1.906a4.016,4.016,0,1,0,8,0V35.048a4.016,4.016,0,1,0-8,0ZM29.816,41.2a2.631,2.631,0,0,1-1.825-2.53V33.334H26.659A5.34,5.34,0,0,1,21.326,28V5.333A5.34,5.34,0,0,1,26.659,0H55.992a5.34,5.34,0,0,1,5.333,5.333V26.667a6.674,6.674,0,0,1-6.667,6.667H37.992l-5.2,6.933a2.626,2.626,0,0,1-2.112,1.072A2.717,2.717,0,0,1,29.816,41.2ZM23.993,5.333V28a2.669,2.669,0,0,0,2.666,2.668h2.666A1.334,1.334,0,0,1,30.659,32v6.667l5.6-7.468a1.336,1.336,0,0,1,1.067-.533H54.658a4,4,0,0,0,4-4V5.333a2.67,2.67,0,0,0-2.666-2.668H26.659A2.67,2.67,0,0,0,23.993,5.333ZM46.66,26.667v-4c0-1.573-2.812-2.668-5.335-2.668s-5.333,1.094-5.333,2.668v4H33.324v-4c0-2.2,1.663-3.749,3.868-4.592a5.332,5.332,0,0,1-1.2-3.407V13.334a5.334,5.334,0,0,1,10.669,0v1.334a5.342,5.342,0,0,1-1.2,3.407c2.2.842,3.866,2.393,3.866,4.592v4Zm-8-13.333v1.334a2.667,2.667,0,0,0,5.333,0V13.334a2.667,2.667,0,0,0-5.333,0Z" transform="translate(0.557 0.5)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
</template>

<script>
export default {
name: "IconFicheLiaison"
}
</script>

<style scoped>

</style>